<template>
  <div v-if="useConfigurationHint" class="ai-hint-block">
    <BaseElButton
      class="ai-btn"
      type="primary"
      size="small"
      @click="showAiDialog = true"
    >
      <MaterialIcon>
        auto_awesome
      </MaterialIcon>
    </BaseElButton>
    <BaseDialog
      v-if="showAiDialog"
      title="Generate with AI"
      :btn1Loading="hintLoading"
      @close="closeDialog"
      @confirm="getHint"
    >
      <BaseElForm
        ref="formRef"
        class="mb-[40px]"
        label-position="top"
        :model="formData"
        :rules="formRules"
        @submit.native.prevent
      >
        <BaseElFormItem v-if="showKeepOriginalContent" label="是否保留原有內容" prop="keepOriginalContent">
          <BaseElRadioGroup v-model="formData.keepOriginalContent">
            <BaseElRadio :label="true">是</BaseElRadio>
            <BaseElRadio :label="false">否</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>
        <BaseElFormItem label="文案風格" prop="hint-style">
          <BaseElRadioGroup v-model="formData.hintStyle">
            <BaseElRadio label="專業">專業</BaseElRadio>
            <BaseElRadio label="親切">親切</BaseElRadio>
            <BaseElRadio label="興奮">興奮</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>
        <BaseElFormItem label="Prompt" prop="hint" class="hint-input">
          <BaseElInput
            ref="inputRef"
            v-model="formData.hint"
            type="textarea"
            required
            rows="6"
            placeholder="請輸入文字"
            @keypress.native.enter="getHint"
          />
        </BaseElFormItem>
      </BaseElForm>
    </BaseDialog>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent, ref, reactive, watchEffect, nextTick, computed, set, onMounted, onBeforeUnmount } from 'vue'
import { noEmptyRules } from '@/validation'
import { get } from 'lodash'
import { usePermissions } from '@/use/permissions'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'AiHintBlock',
  components: {
    BaseDialog,
    MaterialIcon,
  },
  props: {
    hintLoading: {
      type: Boolean,
      default: false,
    },
    showKeepOriginalContent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm'],
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const useConfigurationHint = computed(() => checkAction('admin.configurationHint.hint'))
    const formData = reactive({
      hint: '',
      keepOriginalContent: false,
      hintStyle: '專業',
    })
    const hint = ref('')
    const formRef = ref(null)
    const showAiDialog = ref(false)
    const inputRef = ref(null)
    const formRules = computed(() => {
      const rules = {
        hint: [noEmptyRules()],
      }
      return rules
    })
    const getHint = async (event) => {
      if (event && event.shiftKey) return
      const valid = await formUtils.checkForm(formRef.value)
      if (valid) {
        emit('confirm', { prompt: get(formData, 'hint'), origin: get(formData, 'keepOriginalContent'), style: get(formData, 'hintStyle') })
        showAiDialog.value = false
        set(formData, 'hint', '')
      } else {
        console.log('error submit!!')
        return false
      }
    }
    const closeDialog = () => {
      showAiDialog.value = false
      set(formData, 'hint', '')
    }
    watchEffect(() => {
      if (showAiDialog.value) {
        nextTick(() => {
          inputRef.value.focus()
        })
      }
    })
    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleKeyDown)
    })

    const handleKeyDown = (event) => {
    // Command + i or Ctrl + i
      if ((event.ctrlKey || event.metaKey) && event.key === 'i') {
        showAiDialog.value = true
      }
    }
    return {
      hint,
      showAiDialog,
      getHint,
      inputRef,
      formRules,
      formRef,
      formData,
      useConfigurationHint,
      closeDialog,
    }
  },
})
</script>

<style lang="postcss" scoped>
.ai-btn {
  @apply min-w-0 mb-[20px]
}
.hint-input {
  @apply mb-[20px]
}
::v-deep .el-input, ::v-deep .el-textarea {
  @apply !w-[560px];
}
</style>
